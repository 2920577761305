import logo from './logo.svg';
import './App.css';
import { useLocation, } from 'react-router';
import {useParams} from 'react-router-dom'
import { useEffect, useState } from 'react';
import { isAndroid, isIOS } from 'react-device-detect';
import Lottie from 'react-lottie';
import animationData from './ARScreenAnimation.json'
export function getParams(searchStr){
  if(searchStr==='')return {}
  let par =searchStr.slice(1,(searchStr.length)).split('&')
  // console.log(par)
  let res = {}
  par.forEach(x => res[x.split('=')[0]]=x.split('=')[1])
  // console.log(res)
  // Object.keys(res).forEach( x=> res[x]= Number(res[x]))
  // console.log(res)
  return res
}

function App() {
  const location = useLocation()
  console.log(location)
  
  const params = getParams(location.search)
  console.log(params)
  const AndroidURL = params.android
  const IOSIntent = params.ios
  const AndroidIntent =
  "intent://arvr.google.com/scene-viewer/1.0?file=" +
  AndroidURL +
  "#Intent;scheme=https;package=com.google.android.googlequicksearchbox;action=android.intent.action.VIEW;S.browser_fallback_url=https://developers.google.com/ar;end;";
  useEffect(()=>{
    // if(!location.searchStr)return
    
    // isIOS && 
  },[location])
  function clicked(){
    // setTimeout(()=>{
      isAndroid &&  window.open(AndroidIntent)
      
    // },1000)

  }
  const defaultOptions = {
    animationData:animationData,
    autoplay:true,
    
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    },
    loop:false
}
  return (
    <div className="App">
      {isAndroid && <button className='Button' onClick={clicked}>Enter AR</button>}
      {isIOS && <a rel='ar' target='_self' href={IOSIntent}><button className='Button'>Enter AR</button></a>}

        <Lottie  
          options={defaultOptions}
          // width='100%'
          height='100%'
        />
      
    </div>
  );
}

export default App;
